.paragraph-employee{
  margin-bottom: 100px;
  img{
    margin: 0 auto;
    display: block;
    border: 2px solid black;
    margin-bottom: 50px;
  }
  .employee-name{
    font-size: 30px;
    line-height: 35px;
    font-weight: bold;
  }
}