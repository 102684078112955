header.navigation{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: black;
  padding: 30px 50px;
  .menu{
    list-style-type: none;
    display: flex;
    flex-flow: row wrap;
    &-item{
      &:hover{
        .menu-subtree{
          display: block;
        }
      }
      a{
        color: white;
        text-decoration: none;
        font-weight: bold;
        font-size: 25px;
      }
      &:not(:last-of-type){
        margin-right: 50px;
      }
    }
    &-subtree{
      margin-top: 20px;
      display: block;
      display: none;
      transition: max-height .5s;
      .menu-item{
        a{
          font-size: 15px;
        }
      }
    }
  }
}